<template>
  <v-card>
    <AreaHeader @openToAdd="openDialog" @search="changeSearch"></AreaHeader>
    <DatatableAreas :areas="areas" :loading="loading" :search="search" @edit="openEdit" @remove="openRemove"></DatatableAreas>
    <WDialog
      width="500"
      :dialog="dialog"
      :closeDialog="closeDialog"
      :title="titleDialog"
      :subtitle="subtitleDialog"
    >
      <template v-slot:form-dialog>
        <FormArea
          v-if="dialog"
          :loading="loading"
          :areaEdited="areaEdited"
          @beforeUpdate="updateArea"
          @beforeCreate="createArea"
        ></FormArea>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import DatatableAreas from "./DatatableAreas.vue";
import FormArea from "@/components/forms/FormArea.vue";
import AreaHeader from "./AreaHeader.vue";
import WDialog from "@/components/dialogs/WDialog.vue";
import useAreas from "@/composables/useAreas";

export default {
  components: {
    DatatableAreas,
    FormArea,
    AreaHeader,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      areaEdited,
      areas,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createArea,
      updateArea,
    } = useAreas();

    return {
      dialog,
      areas,
      search,
      loading,
      areaEdited,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createArea,
      updateArea,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
