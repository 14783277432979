import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useAreas = () => {

  // base data
  const dialog = ref(false);
  const areaEdited = ref(null);
  const areas = ref([]);
  const search = ref("");
  const loading = ref(false);

  // computed properties
  const titleDialog = computed(() =>
    areaEdited.value ? "Editar rubro" : "Agregar rubro"
  );
  const subtitleDialog = computed(() =>
    areaEdited.value ?
      "Rellene los campos correctamente para modificar los datos del rubro" :
      "Rellene los campos correctamente para validar los datos del nuevo rubro"
  );

  const closeDialog = () => {
    dialog.value = false;
    areaEdited.value = null;
  }

  // methods
  const getAreasList = async () => {
    const { data, status } = await store.dispatch("fetchAreas")
    if (status != 200) return;
    areas.value = data;
  }

  const createArea = async (area) => {
    loading.value = true;
    const { data, status } = await store.dispatch("createArea", area)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getAreasList();
    closeDialog();
  }

  const updateArea = async (area) => {
    loading.value = true;
    const { data, status } = await store.dispatch("updateArea", area)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getAreasList();
    closeDialog();
  }

  const removeArea = async (area) => {
    const val = await store.dispatch("generateConfirmDialog", {});
    if (!val) return;
    const { data, status } = await store.dispatch("removeArea", area)
    if (status != 200 && status != 201 && status != 204) return;
    await getAreasList();
  }

  getAreasList();

  return {
    dialog,
    areaEdited,
    areas,
    search,
    loading,
    // computed
    titleDialog,
    subtitleDialog,
    // methods
    changeSearch(ev) {
      search.value = ev
    },
    openDialog() {
      dialog.value = true;
    },
    openEdit(area) {
      dialog.value = true;
      areaEdited.value = {...area };
    },
    openRemove: removeArea,
    closeDialog,
    createArea,
    updateArea
  };
}

export default useAreas;
